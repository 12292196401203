<template>
  <div class="main" style="overflow-x:hidden">
    <template>
      <div id="app">
        <router-view />
      </div>
    </template>
    <div id="screen" class="screen"></div>
    <transition mode="out-in" name="leftSlide">
      <div class="windowLeft"  v-show="leftShow">
        <big-left ref="Left"></big-left>
      </div>
    </transition>
    <transition mode="out-in" name="rightSlide">
      <div class="windowRight"  v-show="rightShow">
        <bigRight ref="Right"></bigRight>
      </div>
    </transition>

    <div class="downBottom">
      <div
          class="btn"
          v-for="(item, key) in akbutton"
          :key="key"
          @click="cameraMove(item)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>

import ZThree from "@/three/ZThree";
import BaseNet from "@/three/baseNet";
import { akbutton, tube, textSp, pengquan,meters } from "@/assets/mock/yanshi.js";
import * as THREE from "three";
import bigLeft from "@/components/code";
import bigRight from "@/components/textcode";
import { createSprite } from "@/three/sprite";
import shuichang from "@/assets/mock/shuichang";
import meterData from "@/assets/mock/meterData";
import waterPipe from "@/assets/mock/waterPipe";
let app,
    camera,
    baseNet,
    serverInfo = {},
    scene,
    modelUrlPath,
    modelUrlPathName,
    renderer,
    cameraCurve,
    speed = 0.001,
    isCameraMove = false,
    renderOrder = 0,
    controls,
    clock,
    cityModel,
    // 车轮数组
    cavasHtmlGroup = new THREE.Group(),
    buildingGroup = new THREE.Group(),
    meterGroup = new THREE.Group(),

    isPenquan = false,
    isPause = false,
    dataControls = {},
    meterdata,
    waterpipe,
    progress = 0;

export default {
  name: "Home",
  components: {
    bigLeft,
    bigRight,
  },
  data() {
    return {
      isCarMove: false,
      akbutton: akbutton,
      isLoaded: false,
      loading: null,
      leftShow: false,
      rightShow: false,
      modelPath: null,
    };
  },
  methods: {
    async initZThree() {
      app = new ZThree("screen");
      app.initThree();
      app.initHelper();
      app.initOrbitControls();
      app.initLight(0.8);
      app.loaderSky("texture/sky4/");
      // 点击精灵飞行
      app.initRaycaster((obj,point) => {
        // console.log(app.camera)
        // console.log(app.controls)
        var meteritem ;
        if(obj.type === "Mesh"){
          if(obj.jc_type === 'waterTube'){
            //dianjiguandao
            tube.forEach((item) => {
              if (item.name == obj.name) {
                waterpipe.showData(item,[point.x,point.y,point.z]);
              }
            });

          }
          meteritem = obj.parent;
        }else
          meteritem = obj;
        // console.log(obj)

        if(meteritem.jc_type&&meteritem.jc_type=='meter'){
          meterdata.changeMeter(meteritem,meteritem.meterId);
        }
      });
      window.app = app;
      camera = app.camera;
      scene = app.scene;
      renderer = app.renderer;
      controls = app.controls;
      controls.maxPolarAngle = Math.PI / 2.2; // 设置最大角度 防止 入地下
      controls.target = new THREE.Vector3(12, 144, -132);
      clock = new THREE.Clock();
      let apath = modelUrlPath ||"model/ankao/";
      let aName = modelUrlPathName ||"changqubig";

      cityModel = await app.loaderGlbDracoModel(
          apath,
          aName,
          (percentage) => {
            // if(this.loading&&this.loading.visible)
            // this.loading.text = "资源正在加载中，请稍后"+"···"+percentage+"%";
          }
      );

      cityModel.traverse((model) => {
        if (model.isMesh) {
          model.frustumCulled = false;
          if (
              model.material &&
              model.material.map &&
              model.material.map.encoding
          ) {
            model.material.map.encoding = THREE.LinearEncoding;
          }
        }
      });
      buildingGroup.add(cityModel);
      buildingGroup.renderOrder = renderOrder++;
      scene.add(buildingGroup);


      scene.add(meterGroup);
      // 创建水表
      meters.forEach((item) => {
        app.loadMeter(meterGroup,item.type,item.meterId,item.position,item.rotateXYZ);
      });

      // 添加平面
      let plane = app.loaderPlane("texture/caodi.jpg");
      plane.position.set(0, -0.5, 0);
      plane.renderOrder = 2;
      scene.add(plane);

      // 创建文本
      textSp.forEach((item) => {
        createSprite(cavasHtmlGroup, item.name, item.position);
      });
      cavasHtmlGroup.renderOrder = renderOrder++;
      scene.add(cavasHtmlGroup);
      // 创建用水管道
      tube.forEach((item) => {
        if (item.type == "tube") {
          let tube = app.loaderWaterTube(item.route,item.name);
          console.log(tube)
          buildingGroup.add(tube);
        }
      });
      this.loading.close();
      waterpipe = new waterPipe(scene);
      camera.position.set(-41, 1021, 312);

      app.flyTo({
        position: [433.456706289105, 219.68312417497387, 540.5150079031882],
        controls: [108.21390562685808, -129.51278441906786, 67.9566070128961],
        duration: 1500,
      });
      // this.loadShebei();
      app.render(() => {
        controls.update(clock.getDelta());
        renderer.render(scene, camera);
        TWEEN.update();
        buildingGroup.traverse((model) => {
          if (model.jc_type == "waterTube") {
            model.material.map.offset.x -= 0.01;
          }
          if (isPenquan &&model.jc_type == "penquan") {
            let size = model.jc_size;
            for (var p = 0; p < 500; p++) {
              var particle = model.geometry.vertices[p];
              particle.x += particle.velocity.x;
              particle.y += particle.velocity.y;
              particle.z += particle.velocity.z;
              particle.velocity.y -= size / 8;
              if (particle.y < size * 2.5) {
                particle.x = particle.y = particle.z = 0;
                var dX, dY, dZ;
                dY = Math.random() * size + size * 2.5;
                dX = Math.random() * size - size / 2;
                dZ = Math.random() * size - size / 2;
                particle.velocity = new THREE.Vector3(dX / 1, dY / 1, dZ / 1);
              }
            }
            model.geometry.verticesNeedUpdate = true;
          }

          if ( model.jc_type == "penguan") {
            const vertices = [];
            for (var col = 0; col < 20; col++) {
              let py = model.jc_pypz[col][0];
              let pz = model.jc_pypz[col][1];
              for (var row = 0; row < 30; row++) {
                let i = row / 30 + (1 / 30) * Math.random();
                const linepoints = model.jc_line1.getPointAt(i);
                let ppy = (py * row) / 30 + (row / 30) * Math.random() * 3;
                let ppz = (pz * row) / 30 + (row / 30) * Math.random() * 3;
                vertices.push(
                    linepoints.x,
                    linepoints.y + ppy,
                    linepoints.z + ppz
                );
              }
            }
            model.geometry.setAttribute(
                "position",
                new THREE.Float32BufferAttribute(vertices, 3)
            );
          }
        });
        if (isCameraMove) {
          if (progress <= 1) {
            const point = cameraCurve.getPointAt(progress); //获取样条曲线指定点坐标，作为相机的位置
            const tangent = cameraCurve.getTangentAt(progress);
            const lookAtVec = tangent.add(point);

            app.camera.position.set(point.x, point.y, point.z);
            if (lookAtVec.x == 334 && lookAtVec.z == 182 && lookAtVec.y < 48)
              lookAtVec.x = lookAtVec.x - 5;
            app.controls.target.set(lookAtVec.x, lookAtVec.y, lookAtVec.z);
            app.controls.update();
            progress += speed;
          } else {
            if (dataControls.lineName === "绿化喷滴灌") {
              this.showPenquan();
              this.rightShow = true;
              this.$refs["Right"].showPD();
              console.log("showPenquan");
            }
            if (dataControls.lineName === "一、二期") {
            }
            isCameraMove = false;
            isPause = false;
            progress = 0;
          }
        }
      });
    },
    showPenquan() {
      if (isPenquan) return;
      isPenquan = true;
      pengquan.forEach((item) => {
        let pq = app.createPenQuan(0.2, [item[0], item[1], item[2]]);
        buildingGroup.add(pq);
      });
    },
    removePenquan() {
      if (!isPenquan) return;
      isPenquan = false;
      app.removeMesh("penquan");
    },
    async loadShebei() {
      let apath = "model/ankao/";
      let aName = "shebei";
      let sebei = await app.loaderGlbDracoModel(
          apath,
          aName,
          (percentage) => {}
      );

      sebei.traverse((model) => {
        if (model.isMesh) {
          model.frustumCulled = false;
          if (
              model.material &&
              model.material.map &&
              model.material.map.encoding
          ) {
            model.material.map.encoding = THREE.LinearEncoding;
          }

        }
      });
      new shuichang(sebei);
      buildingGroup.add(sebei);
    },
    cameraMove(item) {
      if (isCameraMove && !isPause) {
        isCameraMove = false;
        isPause = true;
        console.log("暂停");
        return;
      } else if (!isCameraMove && isPause) {
        console.log("播放");
        isCameraMove = true;
        isPause = false;
        return;
      }
      if (item.type == "line") {
        // this.$refs["Left"].show = false;
        cameraCurve = item.route;
        speed = item.speed;
        isCameraMove = true;
        dataControls.lineName = item.name;
        if (item.name != "厂区") {
          this.leftShow = false;
        } else if (item.name == "厂区") {
          // this.$refs["Left"].getdom();
          this.leftShow = true;
          this.$refs["Left"].showData();
        }
        if (dataControls.lineName != "绿化喷滴灌") {
          this.removePenquan();
          this.rightShow = false;
          console.log("removePenquan");
        }
      } else if (item.type == "camera") {
        // this.leftShow = !this.leftShow;
        // this.rightShow = !this.rightShow;
        this.leftShow = false;
        this.rightShow = false;
        waterpipe.deleteData();
        meterdata.deleteData();
        app.flyTo({
          position: item.cameraPosition,
          controls: item.controls,
          duration: 1500,
        });
      }
    },

    confirm(mag) {
      this.$message({
        message: mag,
        type: "success",
        duration: 3000,
      });
    },
  },
  mounted() {
    this.baseNet = new BaseNet();
    this.baseNet.IsAgent(confirm);
    this.baseNet.getGPU(confirm);

    let ip = this.baseNet.getQueryString("IP");
    modelUrlPath = this.baseNet.getQueryString("modelUrlPath");
    modelUrlPathName = this.baseNet.getQueryString("modelUrlPathName");

    ip = ip.replaceAll("%3A", ":").replaceAll("%2F", "/");
    let USER_ID = this.baseNet.getQueryString("USER_ID");
    let TOKEN = this.baseNet.getQueryString("TOKEN");
    var success = this.baseNet.checkToken(ip,USER_ID,TOKEN);
    if(!success){
      // return;
    }
    serverInfo.ip = ip;
    serverInfo.USER_ID = USER_ID;
    serverInfo.TOKEN = TOKEN;
    meterdata = new meterData(serverInfo);

    this.$refs["Left"].codeInit(ip, USER_ID, TOKEN);
    this.loading = this.$loading({
      lock: true,
      text: "资源正在加载中，请稍后",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 1)",
    });
    this.initZThree();
  },
};
</script>
<!--<style lang='less' scoped>-->

<!--</style>-->
