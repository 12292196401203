<template>
  <div class="main">

    <div id="screen" class="screen"></div>
    <div class="windowLeft">
      <transition mode="out-in" name="leftSlide">
        <big-left ref="Left" v-show="leftShow"></big-left>
      </transition>
    </div>
    <div class="windowRight">
      <transition mode="out-in" name="rightSlide">
        <bigRight ref="Right" v-show="rightShow"></bigRight>
      </transition>
    </div>
    <div class="downBottom">
      <div
          class="btn"
          v-for="(item, key) in address"
          :key="key"
          @click="fly(item)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import ZThree from "@/three/ZThree";
import ModelSplit from "@/three/baozha";
import bigRight from "@/components/textcode";
import bigLeft from "@/components/excelcode";
import address from "@/assets/mock/product.js";
import * as THREE from "three";
import BaseNet from "@/three/baseNet";

let app,
    camera,
    baseNet,
    scene,
    renderer,
    renderOrder = 0,
    modelUrlPath,
    modelUrlPathName,
    controls,
    clock,
    cityModel,
    buildingGroup = new THREE.Group(),
    skyTexture,
    modelSplit,
    bloomComposer

export default {
  name: "Home",
  components: {
    bigLeft,
    bigRight,
  },
  data() {
    return {
      // 小车移动状态
      isCarMove: false,
      address: address,
      isLoaded: false,
      loading: null,
      rightShow: false,
      isOpen: false,
    };
  },
  methods: {
    async initZThree() {
      app = new ZThree("screen");
      app.initThree();
      app.initHelper();
      app.initOrbitControls();
      app.initLight(0.8);
      // skyTexture = app.loaderSky("texture/sky3/");
      // bloomComposer = app.bloomComposer();
      modelSplit = new ModelSplit();
      // 点击精灵飞行
      app.initRaycaster((obj) => {
        console.log(app.camera)
        console.log(app.controls)
      });
      window.app = app;
      camera = app.camera;
      scene = app.scene;
      renderer = app.renderer;
      controls = app.controls;
      controls.maxPolarAngle = Math.PI / 2.2; // 设置最大角度 防止 入地下
      controls.target = new THREE.Vector3(2, 44, -32);
      clock = new THREE.Clock();

      renderer.setClearColor( 0x333333 );

      let apath = modelUrlPath ||"model/meter/0327/";
      let aName = modelUrlPathName ||"111111";
      apath = "model/meter/test/";
      aName = "楼一";

      cityModel = await app.loadGLBModel(
          apath,
          aName,
          (percentage) => {
            // if(this.loading&&this.loading.visible)
            // this.loading.text = "资源正在加载中，请稍后"+"···"+percentage+"%";
          }
      );

      // 创建反射贴图
      const reflectionCubeMap = new THREE.CubeTextureLoader()
          .setPath('textures/sky3/')
          .load([
            'px.png', 'nx.png',
            'py.png', 'ny.png',
            'pz.png', 'nz.png'
          ]);
// 创建纹理加载器
      var textureLoader = new THREE.TextureLoader();

// 加载纹理
      var aoMapTexture = textureLoader.load('texture/jstt1.jpg');

      console.log(cityModel)
      cityModel.traverse((model) => {
        if (model.isMesh) {
          model.frustumCulled = false;
          if (
              model.material
          ) {
            model.material.side = THREE.DoubleSide//正反面显示
            // model.material.map.encoding = THREE.LinearEncoding;
            // model.material.envMap = reflectionCubeMap
            // model.material.metalness = 1
            // model.material.roughness = 0.5
            // model.material.roughness = 0.5
            // model.material.opacity = 0.5; // 将材质的透明度设置为0.5
            // model.material.aoMap = aoMapTexture; // 将材质的aoMap设置为一个纹理
            // model.material.aoMapIntensity = 0.5; // 将材质的aoMapIntensity设置为0.5
          }
        }
      });
      console.log(THREE.DoubleSide)
      console.log(cityModel);
      cityModel.position.set(0,0,0);
      cityModel.scale.set(100,100,100)
      buildingGroup.add(cityModel);
      buildingGroup.renderOrder = renderOrder++;
      scene.add(buildingGroup);

      // // 添加平面
      // let plane = app.loaderPlane("texture/plane.png");
      // plane.position.set(0, -30, 0);
      // plane.renderOrder = 2;
      // scene.add(plane);

      const grid = new THREE.Group();

      const grid1 = new THREE.GridHelper( 500, 30, 0x555555 );
      grid1.material.color.setHex( 0x555555 );
      grid1.material.vertexColors = false;
      grid.add( grid1 );

      const grid2 = new THREE.GridHelper( 500, 6, 0x111111 );
      grid2.material.color.setHex( 0x111111 );
      // grid2.material.depthFunc = THREE.AlwaysDepth;
      grid2.material.vertexColors = false;
      grid.add( grid2 );
      grid.position.y = -40;
      scene.add(grid);

      this.loading.close();
      let text = [
        "DN40-300(T50/PN16)",
        [
          '· 多点调节的专利技术，叶轮受水力冲击平衡',
          '· 真正可拆互换式机芯，维修便捷',
          '· 高强度塑料外壳保护的计数器可360°内任意旋转',
          '· 钢化玻璃计数器防水等级为IP68保证了计数器表面不起雾',
          '· 应用水力动平衡的技术，大大提高了流量低区（灵敏度）和高区（过载）性能',
          '· 表壳内外环氧树脂喷涂防腐处理，整表符合国家卫生部和欧洲饮用水标准要求'
        ],
        "水平螺翼可拆式冷水水表",
      ];
      this.$refs["Right"].showPD(text[0],text[1],text[2]);
      modelSplit.quit();
      modelSplit.setOnlySonSplitModel(cityModel);


      app.flyTo({
        position: [41,56,135],
        controls: [0, 0,  0],
        duration: 1500,
      });

      app.render(() => {
        controls.update(clock.getDelta());
        renderer.render(scene, camera);
        // bloomComposer.render();
        modelSplit.update();
        TWEEN.update();
      });
    },
    fly(item) {
      if(item.name=='复位'){
        app.flyTo({
          position: item.cameraPosition,
          controls: item.controls,
          duration: 1500,
        });
      }else if(item.name=='透视'){
        cityModel.traverse((model) => {
          if (model.isMesh) {
            app.shaderObj(model);
          }
        });
      }else if(item.name=='切换'){
        if(this.isOpen){
          modelSplit.quitSplit();
        }else {
          modelSplit.startSplit();
        }
        this.isOpen = !this.isOpen;
      }
      else if(item.name=='特征'){
        // modelSplit.quit();

        // modelSplit.setSplitModel(cityModel);
        if(this.rightShow){
          this.rightShow = false;
          // app.meshMove(cityModel,{x:cityModel.position.x+50,y:cityModel.position.y,z:cityModel.position.z});
          // this.controls.target.setX(this.controls.targetX+50)
          // this.controls.update()
        }else{
          this.rightShow = true;
          // app.meshMove(cityModel,{x:cityModel.position.x-50,y:cityModel.position.y,z:cityModel.position.z});
          // this.controls.target.setX(this.controls.targetX-50)
          // this.controls.update()
        }
      }
    },
  },
  mounted() {

    this.baseNet = new BaseNet();
    this.baseNet.IsAgent(confirm);
    this.baseNet.getGPU(confirm);

    let ip = this.baseNet.getQueryString("IP");
    modelUrlPath = this.baseNet.getQueryString("modelUrlPath");
    modelUrlPathName = this.baseNet.getQueryString("modelUrlPathName");

    ip = ip.replaceAll("%3A", ":").replaceAll("%2F", "/");
    let USER_ID = this.baseNet.getQueryString("USER_ID");
    let TOKEN = this.baseNet.getQueryString("TOKEN");
    var success = this.baseNet.checkToken(ip,USER_ID,TOKEN);
    // if(!success){
    //   return;
    // }


    this.loading = this.$loading({
      lock: true,
      text: "资源正在加载中，请稍后",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 1)",
    });
    this.initZThree();
  },
};
</script>
