<template>
  <div class="main">
    <div id="screen" class="screen"></div>
    <div class="downBottom">
      <div
          class="btn"
          v-for="(item, key) in akbutton"
          :key="key"
          @click="buttonClick(item)"
      >
        {{ item.name }}
      </div>
    </div>

  </div>
</template>

<script>
import ZThree from "@/three/ZThree";
import { akbutton, tube, textSp, pengquan,meters } from "@/assets/mock/jwxq.js";
import * as THREE from "three";
import GLTFUtil from "@/utils/GLTFUtil";
let app,
    camera,
    scene,
    renderer,
    renderOrder = 0,
    modelUrlPath,
    modelUrlPathName,
    controls,
    clock,
    cityModel,
    cityModelShader,
    buildingGroup = new THREE.Group(),
    buildingShaderGroup = new THREE.Group(),
    skyTexture

export default {
  name: "Home",
  components: {
  },
  data() {
    return {
      // 小车移动状态
      isCarMove: false,
      isLoaded: false,
      akbutton: akbutton,
      loading: null,
    };
  },
  methods: {
    async initZThree() {
      app = new ZThree("screen");
      app.initThree();
      app.initHelper();
      app.initOrbitControls();
      app.initLight();
      // skyTexture = app.loaderSky("texture/sky3/");

      // 点击精灵飞行
      app.initRaycaster((obj) => {

      });
      window.app = app;
      camera = app.camera;
      scene = app.scene;
      renderer = app.renderer;
      controls = app.controls;
      controls.maxPolarAngle = Math.PI / 2.2; // 设置最大角度 防止 入地下
      controls.target = new THREE.Vector3(2, 44, -32);
      clock = new THREE.Clock();
      renderer.setClearColor( 0x333333 );
      let apath = modelUrlPath ||"model/ankao/";
      let aName = modelUrlPathName ||"changqu";
      cityModel = await app.loaderGlbDracoModel(
          apath,
          aName,
          (percentage) => {
            if(this.loading&&this.loading.visible)
             this.loading.text = "资源正在加载中，请稍后"+"···"+percentage+"%";
          }
      );
      let  dddd ;
      console.log(cityModel)
      cityModel.traverse((model) => {
        if (model.isMesh) {
          model.frustumCulled = false;
          if (
              model.material &&
              model.material.map &&
              model.material.map.encoding
          ) {
            model.material.map.encoding = THREE.LinearEncoding;
            // model.material.side = THREE.DoubleSide//正反面显示
          }
          console.log(model)
          // const sh = app.getshader()
          // model.material.vertexShader = sh.vertexShader;
          // model.material.fragmentShader = sh.fragmentShader;
          // model.material.uniforms = sh.uniforms;
          // dddd = model;
        }
      });
      cityModel.position.set(0,0,0);
      buildingGroup.add(cityModel);
      buildingGroup.renderOrder = renderOrder++;

      scene.add(buildingGroup);

      // // 添加平面
      // let plane = app.loaderPlane("texture/caodi.jpg");
      // plane.position.set(0, -0.5, 0);
      // plane.renderOrder = 2;
      // scene.add(plane);

      this.loading.close();

      camera.position.set(-41, 1021, 312);
      this.fuwei();

      app.render(() => {
        controls.update(clock.getDelta());
        renderer.render(scene, camera);
        TWEEN.update();

      });
    },
    buttonClick(item) {
      switch (item.index) {
        case 1:
          this.exportModel();
          break;
        case 2:
          // 处理value为2的情况
          this.loadingShow();
          if(!cityModelShader){
            cityModelShader = cityModel.clone();
            console.log(cityModelShader)
            cityModelShader.traverse((model) => {
              if (model.isMesh) {
                app.shaderObj(model);
                console.log(model.name)
              }
            });
            console.log(cityModelShader)
            buildingShaderGroup.add(cityModelShader)
            buildingShaderGroup.renderOrder = 2;

          }
          scene.remove(buildingGroup);
          scene.add(buildingShaderGroup);

          this.loading.close();
          break;
        case 3:
          // 处理value为3的情况
          this.exportModelShader();
          break;
        case 4:
          // 处理value为4的情况
          break;
        default:
          // 处理value不为1-4的情况
          break;
      }
      this.fuwei();
    },
    exportModelShader() {
      GLTFUtil.exporterGlb(buildingShaderGroup);
    },
    exportModel() {
      GLTFUtil.exporterGlb(cityModel);
    },
    fuwei(){
      app.flyTo({
        position: [17, 162, 487],
        duration: 1500,
      });
    },
    confirm(mag) {
      this.$message({
        message: mag,
        type: "success",
        duration: 3000,
      });
    },
    loadingShow() {
      this.loading = this.$loading({
        lock: true,
        text: "资源正在加载中，请稍后",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 1)",
      });
    },
  },
  mounted() {
    this.loadingShow();
    this.initZThree();
  },
};
</script>
