<template>
  <div class="left animated slideInLeft">
    <div class="title1"><font face="楷体">今日数据</font></div>
    <div class="box">
      <div class="data">
<!--        <div class="bg">-->
<!--          <img :src="img_src" />-->
<!--          <div class="title">今日总用水量</div>-->
<!--          <div class="total">{{ devices_total_num }}</div>-->
<!--        </div>-->
        <div class="on_line">
          <img :src="on_src" />
          <div class="normal">今日总用水量</div>
          <div class="normal_num">{{ devices_online_num }} m³</div>
        </div>
        <div class="off_line">
          <img :src="off_src" />
          <div class="off">今日总排水量</div>
          <div class="off_num">{{ devices_offline_num }} m³</div>
        </div>
      </div>
      <div class="bg">
        <img :src="img_src" />
        <div class="title">数据对比</div>
      </div>
      <div
              ref="parentElement"
              style="width: 100%; overflow: hidden; margin-top: -1.5rem"
              id="parentWidth"
      >
          <table>
              <thead>
                    <tr>
                        <td>日期</td>
                        <td>用水量（m³）</td>
                        <td>排水量（m³）</td>
                    </tr>
              </thead>
              <tbody>
                  <tr v-for="(item, index) in ALL_yongshui_new" :key="index">
                        <td>{{item.DATA_DATE}}</td>
                        <td>{{item.yongshui}}</td>
                        <td>{{item.paishui}}</td>
                    </tr>
              </tbody>
          </table>
<!--          <div class="echart" id="mychart" ref="ele" :style="myChartStyle"></div>-->
<!--          <div class="YslChart" ref="YslChart" id="YslChart"   :style="myChartStyle" ></div>-->
<!--        <JCBarChart-->
<!--                ref="barChart"-->
<!--                @set-parent-width="parentWidth = $event"-->
<!--        ></JCBarChart>-->
      </div>
    </div>
  </div>
</template>

<script>
  // import JCBarChart from "@/components/chart/JCbarChart";
  import $ from "@/assets/js/jquery.min.js";
  import { getDayAll,unique } from "../utils/common";
  import * as echarts from "echarts";
  export default {
    name: "Left",
    data() {
      return {
          myChartStyle: { width: "100%", height: "400px" }, //图表样式
        img_src: require("./../assets/images/right.png"),
        on_src: require("./../assets/images/online_icon.png"),
        off_src: require("./../assets/images/offline_icon.png"),
        devices_total_num: 0,
        devices_online_num: 0,
        devices_offline_num: 0,
        barData: null,
        USER_ID: "",
        url: "",
        TOKEN: "",
        ALL_yongshui_new:[]
      };
    },
    created() {},
    mounted() {
      // alert();
      // this.init(this.url, this.TOKEN, this.USER_ID);
    },
    methods: {
        // showChartData(xdata, ydata, ydata2) {
        //     // let this_ = this;
        //     console.log(1111111)
        //     const YslChart = echarts.init(document.getElementById("YslChart"));
        //     YslChart.showLoading({
        //         text: '加载中...',
        //         maskColor: 'rgba(3,3,8,0.1)',
        //         textColor: '#FFF',
        //         color: '#2AD1E2',
        //     })
        //     $("#YslChart").css({"width":$("#parentWidth").width(),"height":$("#parentWidth").height()})
        //     // 基本柱状图
        //     const YslChartoption = {
        //         color:["#E65D17","#F524DE","#FFD82E"],
        //         tooltip: {
        //             trigger: 'axis',
        //             // 鼠标移动柱状图是提示文字
        //             show: true,
        //             axisPointer: {
        //                 type: 'shadow'
        //             }
        //         },
        //         grid: {
        //             left: '10%',
        //             right: '10%',
        //             //bottom: '30%',
        //             top: '20%',
        //             /* containLabel: true*/
        //         },
        //         legend: {
        //             data:[{
        //                 name:'时用量',
        //                 textStyle: {
        //                     fontSize: 16,
        //                     color: '#FFF'
        //                 },
        //             },
        //                 {
        //                     name:'同比（年）',
        //                     textStyle: {
        //                         fontSize: 16,
        //                         color: '#FFF'
        //                     },
        //                 },
        //             ],
        //         },
        //         toolbox: {
        //             show : true,
        //         },
        //         calculable : true,
        //         xAxis : [
        //             {
        //                 type : 'category',
        //                 boundaryGap :true,
        //                 data : xdata,
        //                 axisLine: {
        //                     lineStyle: {
        //                         color: '#CCC'
        //                     }
        //                 },
        //                 axisLabel: {
        //                     color: '#FFF'
        //                 },
        //                 splitLine:{
        //                     show:false
        //                 }
        //             }
        //         ],
        //         yAxis : [
        //             {
        //                 type : 'value',
        //                 axisLine: {
        //                     lineStyle: {
        //                         color: '#CCC'
        //                     }
        //                 },
        //                 axisLabel: {
        //                     color: '#FFF'
        //                 },
        //                 splitLine:{
        //                     show:false
        //                 },
        //             }
        //         ],
        //         dataZoom: [
        //             {
        //                 type: 'inside'
        //             }
        //         ],
        //         series : [{
        //             name:'时用量',
        //             type:'bar',
        //             boundaryGap: true,
        //             // smooth:true,
        //             data:ydata,
        //             barCategoryGap:'40%',
        //             barMaxWidth:60,
        //             markPoint: {
        //                 data: [
        //                     {type: 'max', name: '最大值'}
        //                 ],
        //                 animationDelay: 1000,
        //                 animationDuration: 1000
        //             },
        //             itemStyle: {
        //                 normal: {
        //                     barBorderRadius: [10, 10, 0, 0],
        //                     color: new echarts.graphic.LinearGradient(
        //                         0,1, 0, 0,
        //                         [{
        //                             offset: 0,
        //                             color: '#ffe025'
        //                         },
        //                             {
        //                                 offset: 1,
        //                                 color: '#FFF03B'
        //                             }
        //                         ]
        //                     )
        //                 }
        //             },
        //         },{
        //             name:'同比（年）',
        //             type:'bar',
        //             barMaxWidth:60,
        //             // smooth:true,
        //             markPoint: {
        //                 data: [
        //                     {type: 'max', name: '最大值'}
        //                 ],
        //                 animationDelay: 1000,
        //                 animationDuration: 1000
        //             },
        //             data:ydata2,
        //             itemStyle: {
        //                 normal: {
        //                     barBorderRadius: [10, 10, 0, 0],
        //                     color: new echarts.graphic.LinearGradient(
        //                         0, 1, 0, 0,
        //                         [
        //                             {offset: 0, color: '#517B90'},
        //                             {offset: 1, color: '#F49FA0'}
        //                         ]
        //                     )
        //                 }
        //             },
        //         },
        //         ]
        //     };
        //     // const YslChart = echarts.init(document.getElementById("YslChart"));
        //     YslChart.clear()
        //     YslChart.hideLoading()
        //     YslChart.setOption(YslChartoption);
        //     //随着屏幕大小调节图表
        //     window.addEventListener("resize", () => {
        //         YslChart.resize();
        //     });
        // },
      codeInit(ip, userId, token) {
        this.USER_ID = userId || this.USER_ID;
        this.url = ip || this.url;
        this.TOKEN = token || this.TOKEN;
        this.showData();
      },
      // 方法一
      // 加载显示设备状态、用水曲线
      showData() {
        this.getdata();
        this.getnum();
      },
      getdata() {
        var that = this;
        var xdata = [];
        var ydata = [];
        var ydata2 = [];
        $.get(
                that.url + "/apphome/meterDayDataList",
                {
                  USER_ID: that.USER_ID,
                  TOKEN: that.TOKEN,
                  START_DATA_DATE: that.gettomorrowDate(-7),
                  END_DATA_DATE: that.gettomorrowDate(0),
                  // METER_NUM: 1,
                    WATER_ID:"1790,1799,1800,1801,1791,1792"
                },
                function (data) {
                  if (data.INFO == "success") {
                      var values =Object.values(data.waterTimeMap);
                      // console.log(values)
                      var getDayAllData = getDayAll(that.gettomorrowDate(-7),that.gettomorrowDate(0))
                      var alldata = []
                      $.each(getDayAllData, function (index, value){
                          var date_data = [];
                          $.each(values, function (indexs, valuess){
                                $.each(valuess, function (inds, vals){
                                    if(value == vals.DATA_DATE){
                                         date_data.push(vals);
                                    }
                                })
                          })
                          alldata.push(date_data)
                      })
                      var ALL_yongshui = [];
                      $.each(getDayAllData, function (index, values){
                          var yongshui_obj = {};
                          var yongshui = 0;
                          var paishui = 0;
                            $.each(alldata, function(indexs, valuess){
                                $.each(valuess, function(index, value){
                                      if(value.DATA_DATE == values){
                                          yongshui_obj.DATA_DATE = value.DATA_DATE
                                          if(value.WATER_METER_ID==1790||value.WATER_METER_ID==1799||value.WATER_METER_ID==1800||value.WATER_METER_ID==1801){
                                              yongshui += value.DAY_USE_SUM
                                          }
                                          if(value.WATER_METER_ID==1791||value.WATER_METER_ID==1792){
                                              paishui += value.DAY_USE_SUM
                                          }
                                          yongshui_obj.yongshui = yongshui.toFixed(2)
                                          yongshui_obj.paishui = paishui.toFixed(2)
                                          ALL_yongshui.push(yongshui_obj)
                                          // console.log(ALL_yongshui)
                                      }
                                })
                            })
                      })
                      // $.each(values, function (indexs, values){
                      //     $.each(values, function (index, value) {
                      //         date = value.DATA_DATE;
                      //         if(value.WATER_METER_ID==1790||value.WATER_METER_ID==1799||value.WATER_METER_ID==1800||value.WATER_METER_ID==1801){
                      //             yongshui += value.DAY_USE_SUM
                      //         }
                      //         if(value.WATER_METER_ID==1791||value.WATER_METER_ID==1792){
                      //             paishui += value.DAY_USE_SUM
                      //         }
                      //     });
                      // });
                      let ALL_yongshui_new = unique(ALL_yongshui, "DATA_DATE")
                      ALL_yongshui_new = ALL_yongshui_new.reverse()
                      console.log(ALL_yongshui_new)
                      that.ALL_yongshui_new = ALL_yongshui_new
                      // that.showChartData(xdata, ydata,ydata2);
                    //   var values =Object.values(data.waterTimeMap);
                    //   // console.log(values)
                    // $.each(values, function (index, value) {
                    //   xdata.push(value.DATA_DATE.substr(5, value.DATA_DATE.length));
                    //   ydata.push(value.DAY_USE_SUM);
                    // });
                    // that.barData = {
                    //   xAxisDatas: xdata,
                    //   yAxisDatas: [
                    //     {
                    //       name: "日用水量",
                    //       data: ydata,
                    //     },
                    //   ],
                    // };
                    // JCBarChart.methods.showChartData(xdata, ydata);
                  } else {
                    console.log("token失效");
                  }
                }
        );
      },
      getnum() {
        var that = this;
        $.get(
                that.url + "/apphome/meterDayDataList",
                {
                  USER_ID: that.USER_ID,
                  TOKEN: that.TOKEN,
                  START_DATA_DATE: that.gettomorrowDate(0),
                  END_DATA_DATE: that.gettomorrowDate(1),
                    WATER_ID:"1790,1799,1800,1801,1791,1792"
                },
                function (data) {
                  if (data.INFO == "success") {
                        var values =Object.values(data.waterTimeMap);
                        // console.log(values)
                        var yongshuiliang = 0;
                        var paishuiliang = 0;
                        $.each(values, function (indexs, values) {
                            $.each(values, function (index, value) {
                                if(value.WATER_METER_ID==1790||value.WATER_METER_ID==1799||value.WATER_METER_ID==1800||value.WATER_METER_ID==1801){
                                    yongshuiliang += value.DAY_USE_SUM
                                }
                                if(value.WATER_METER_ID==1791||value.WATER_METER_ID==1792){
                                    paishuiliang += value.DAY_USE_SUM
                                }
                            });
                        });
                    // that.devices_total_num = data.waterTimeList.length;
                    // $.each(data.waterTimeList, function (index, value) {
                    //   if (
                    //           that.timestampToTime(value.LAST_UPDATE_DATE) >=
                    //           that.gettomorrowDate(-3)
                    //   ) {
                    //     online_num++;
                    //   } else {
                    //     offline_num++;
                    //   }
                    // });
                        that.devices_online_num = yongshuiliang.toFixed(2);
                        that.devices_offline_num = paishuiliang.toFixed(2);
                  } else {
                    console.log("token失效");
                  }
                }
        );
      },
      //时间戳转换方法  功能:添加编辑时间选择显示 "-"
      timestampToTime: function (date) {
        var now = new Date(parseInt(date)),
                y = now.getFullYear(),
                m = now.getMonth() + 1,
                d = now.getDate();
        return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
      },
      gettomorrowDate: function (AddDayCount) {
        var dd = new Date();
        dd.setDate(dd.getDate() + AddDayCount); //获取AddDayCount天后的日期
        var y = dd.getFullYear();
        var m = dd.getMonth() + 1; //获取当前月份的日期
        if (m.toString().length == 1) {
          //如果月份是一位数则拼接"g"m = "0" + m;
          m = "0" + m;
        }
        var d = dd.getDate();
        if (d < 10) {
          return y + "-" + m + "-0" + d;
        } else {
          return y + "-" + m + "-" + d;
        }
      },
      //获取echarts 父级的宽度
      // getdom() {
      //   this.$refs["barChart"].getdom();
      // },
    },
  };
</script>
<style lang='less' scoped>
  .left {
    width: 100%;
    height: 100%;
    font-family: Microsoft Yahei;
    color: #fff;
    font-size: 16px;
    .title1 {
      width: 95%;
      font-size: 20px;
      font-weight: 600;
      height: 50px;
      line-height: 50px;
      background-image: url("./../assets/images/title.png");
      background-size: 100% 100%;
      padding-left: 5%;
    }
    .data {
      padding: 1rem 1rem 1rem 0.5rem;
      .on_line {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 3%;
        margin-top: 0.8rem;
        width: 97%;
        height: 2.5rem;
        background-image: url("./../assets/images/on_line.png");
        position: relative;
        display: flex;
        flex-direction: row;
        img {
          height: 1.5rem;
          width: 1.5rem;
          margin: 0.5rem 1rem;
        }
        .normal {
          font-weight: 800;

          width: 13rem;
          height: 40px;
          line-height: 40px;
          text-align: left;
        }
        .normal_num {
          color: #3ee5ed;
          font-weight: 800;
          font-size: 28px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          float: right;
          margin: 0 0.4rem;
        }
      }
      .off_line {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 3%;
        margin-top: 0.8rem;
        width: 97%;
        height: 2.5rem;
        background-image: url("./../assets/images/off_line.png");
        position: relative;
        display: flex;
        flex-direction: row;
        img {
          height: 1.5rem;
          width: 1.5rem;
          margin: 0.5rem 1rem;
        }
        .off {
          font-weight: 800;
          width: 13rem;
          height: 40px;
          line-height: 40px;
          text-align: left;
        }
        .off_num {
          color: #f1d2e4;
          font-weight: 800;
          font-size: 28px;
          height: 40px;
          line-height: 40px;
          /* width: 222px; */
          text-align: center;
          float: right;
          margin: 0 0.4rem;
        }
      }
    }
  }
  .box {
    background-image: url("./../assets/images/box.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 85%;
  }

  .bg {
    display: flex;
    flex-direction: row;
    // padding: 1rem .5rem;
    img {
      height: 40px;
      width: 40px;
      vertical-align: middle;
    }
    .total {
      color: aqua;
      font-weight: bold;
      font-size: 24px;
      margin: 0.2rem 0.5rem;
    }
    .title {
      width: 4rem;
      font-weight: 500;
      height: 40px;
      line-height: 40px;
    }
  }
    #parentWidth table{width:95%;margin: 2.5rem auto 0}
    #parentWidth table td {width: 30%;text-align: center;line-height: 2.8rem;border: 1px solid #0d4e82}
    #parentWidth table td:first-child{border-left: none}
    #parentWidth table td:last-child{border-right: none}
</style>
