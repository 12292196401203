import * as THREE from "three";
let y = 5;
let y1 = 48;

function getVector3(v3) {
    let d = [];
    let sum = 50;
    const addData = (item) => {
        if (d.length == 0)
            d.push(item);
        else {
            let lastItem = d[d.length - 1];
            let deptx = (item.x - lastItem.x) / sum;
            let depty = (item.y - lastItem.y) / sum;
            let deptz = (item.z - lastItem.z) / sum;
            for (let i = 1; i < sum; i++) {
                d.push(new THREE.Vector3(
                    lastItem.x + deptx * i, lastItem.y + depty * i, lastItem.z + deptz * i
                ));
            }
            d.push(item);
        }
    };
    v3.forEach((item) => {
        addData(item);
    })
    let route = new THREE.CatmullRomCurve3(d, false);
    return route;
}


let route1 = [
    new THREE.Vector3(
        96, y, 420
    ),
    new THREE.Vector3(
        96, y, 323
    ),
    new THREE.Vector3(
        98, y, 323
    ),

    new THREE.Vector3(
        310, y, 323
    ),
];
let route2 = [
    new THREE.Vector3(
        310, y, 323
    ),
    new THREE.Vector3(
        332, y, 323
    ),

    new THREE.Vector3(
        334, y, 323
    ),
    new THREE.Vector3(
        334, y, 182
    ),
    new THREE.Vector3(
        334, y1, 182
    ),
    new THREE.Vector3(
        36, y1, 20
    ),
    new THREE.Vector3(
        36, y, 30
    ),
    new THREE.Vector3(
        36, y, 100
    ),
];
let route3 = [
    new THREE.Vector3(
        36, y, 100
    ),
    new THREE.Vector3(
        36, y, 318
    ),

    new THREE.Vector3(
        36, y, 320
    ),
    new THREE.Vector3(
        108, y, 320
    ),
    new THREE.Vector3(
        108, y, 220
    ),
];
let gdy = 1;
let route4 = [
    new THREE.Vector3(
        96, gdy, 420
    ),
    new THREE.Vector3(
        96, gdy, 405
    ),
    new THREE.Vector3(
        218, gdy, 405
    ),
    new THREE.Vector3(
        218, gdy, 360
    ),
];
let route5 = [
    new THREE.Vector3(
        340, gdy, 323
    ),
    new THREE.Vector3(
        330, gdy, 323
    ),
    new THREE.Vector3(
        330, gdy, 405
    ),
    new THREE.Vector3(
        220, gdy, 405
    ),
    new THREE.Vector3(
        220, gdy, 360
    ),
];
let route6 = [
    new THREE.Vector3(
        212, gdy, 340
    ),
    new THREE.Vector3(
        212, gdy, 324
    ),
    new THREE.Vector3(
        40, gdy, 324
    ),
    new THREE.Vector3(
        40, gdy, 20
    ),
];
export let akbutton = [
    {
        name: '添加水表',
        index: 1
    },
    {
        name: 'X+++',
        index: 2
    },
    {
        name: 'X---',
        index: 3
    },
    {
        name: 'Y+++',
        index: 4
    },
    {
        name: 'Y---',
        index: 5
    },
    {
        name: 'ROTATE',
        index: 6
    },
]

export let tube = [
    {
        name: '管道总表到水泵房1',
        modelname: 'SHDN200PE-638g',
        position: [-218.4089150128667, 177.53370260091157, -21.851148072842484],
        cameraPosition: [-523, 173, 123],
        route: getVector3(route4),
        type: "tube",
        model: "DN200",
        matera: "PE",
        speed: 0.002
    },
    {
        name: '管道总表到水泵房2',
        modelname: 'SHDN165PE-968g',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route5),
        type: "tube",
        model: "DN165",
        matera: "PE",
        speed: 0.002
    },
    {
        name: '管道水泵房到绿化',
        modelname: 'SHDN315PE-2358g',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route6),
        type: "tube",
        model: "DN315",
        matera: "PE",
        speed: 0.002
    },
    {
        name: '管道水泵房到绿化',
        modelname: 'SHDN110PE-206g',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route6),
        type: "tube",
        model: "DN110",
        matera: "PE",
        speed: 0.002
    },
    {
        name: '管道水泵房到绿化',
        modelname: 'SHDN100PE-706g',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route6),
        type: "tube",
        model: "DN100",
        matera: "PE",
        speed: 0.002
    },
    {
        name: '管道水泵房到绿化',
        modelname: 'SHDN150PE-250g',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route6),
        type: "tube",
        model: "DN150",
        matera: "PVC",
        speed: 0.002
    },
]
export let meters = [
    {
        name: '德堡路市政总表',
        position: [551.0651060867685, 1.6, 423.4],
        rotateXYZ: [0, Math.PI/1.8, 0],
        cameraPosition: [-523, 173, 123],
        type: "meter",
        meterId: 1492
    }
]
export let textSp = [
    {
        name: 'A栋',
        position: [111.71235587654394, 13.911935671301023, 114.76128190961768],
        type: "Sprite",
    },
    {
        name: 'P栋',
        position: [248.29313710850641, 45.91567892535305, 124.72028873710038],
        type: "Sprite",
    },
    {
        name: 'N栋',
                position: [594.1011299819496, 43.24412541391602, 384.4030587759069],
        cameraPosition: [439.42607442490953, 280.4437072630144, 759.9867584185006],
        cameraControls: [679.3293353122097, -69.4219041360142, 293.2634241362711],
        type: "Sprite",
    },
    {
        name: 'CUB2',
        position: [112.02164845394536, 23.65347939162899, 195.30469986251188],
        type: "Sprite",
    },
    {
        name: 'CUB3',
        position: [111.89804619575085, 23.624180873129035, 272.9379017353631],
        type: "Sprite",
    },
]

let buildingIndex = 1;
export let buildingInfo = [
    {
        name: '先进材料楼',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [594.1011299819496, 43.24412541391602, 384.4030587759069],
        cameraPosition: [439.42607442490953, 280.4437072630144, 759.9867584185006],
        cameraControls: [679.3293353122097, -69.4219041360142, 293.2634241362711],
        type: "Sprite",
    },
    {
        name: '淞沪路门卫室',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [543.6311288203576, 2.9047930485225177, 482.22378468457873],
        cameraPosition: [572.3925008676877, 97.28490284627622, 570.0860899304429],
        cameraControls: [401.16851035775625, -125.23558703377236, 230.7347971162555],
        type: "Sprite",
    },
    {
        name: '环境科学楼',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [499.77548602531215, 30.487998920959473, 411.1197634555006],
        cameraPosition: [441.4661690453984, 340.6261862864901, 735.043311653121],
        cameraControls: [508.3646272208245, -119.14527917762386, 308.5166568867055],
        type: "Sprite",
    },
    {
        name: '',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [644.4990473290588, 12.55215501734131, 105.28961064447917],
        cameraPosition:[523.280200760491, 359.28396490059885, 406.3903390745479],
        cameraControls: [628.5779563636178, 73.9318034275019, 182.58526698681862],
        type: "Sprite",
    },
    {
        name: '信息科学与工程学院',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position:[573.1459980488698, 29.168258681433162, 162.5013330555881],
        cameraPosition: [356.8625572365352, 412.6136838041476, 489.7172471868763],
        cameraControls:[500.10690116964673, 24.427981972396765, 185.25861427032328],
        type: "Sprite",
    },
    {
        name: '交叉学科楼1号楼',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [409.7753850511862, 20.460869058945047, 255.21841846322377],
        cameraPosition: [310.3306039641021, 299.26708407928857, 600.7896198800606],
        cameraControls: [382.6150000989839, -73.21165117814964, 254.47083871263013],
        type: "Sprite",
    },
    {
        name: '',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [594.1011299819496, 43.24412541391602, 384.4030587759069],
        cameraPosition: [439.42607442490953, 280.4437072630144, 759.9867584185006],
        cameraControls: [679.3293353122097, -69.4219041360142, 293.2634241362711],
        type: "Sprite",
    },
    {
        name: '',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [594.1011299819496, 43.24412541391602, 384.4030587759069],
        cameraPosition: [439.42607442490953, 280.4437072630144, 759.9867584185006],
        cameraControls: [679.3293353122097, -69.4219041360142, 293.2634241362711],
        type: "Sprite",
    },
    {
        name: '生命科学学院',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [430.77141923829384, 33.868118757770404, 21.955949260862145],
        cameraPosition: [474.35271575586404, 308.8211418008584, 448.0851400052894],
        cameraControls: [451.1308410106423, 88.72831113137471, 117.89148442940639],
        type: "Sprite",
    },
    {
        name: '技术工程学院',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [366.1775956637854, 19.795012435965194, 120.45141305269821],
        cameraPosition:[447.3896295237283, 290.8024294017682, 462.7921373713623],
        cameraControls: [411.33529715851586, 70.70989608948119, 133.7521372813532],
        type: "Sprite",
    },
    {
        name: '上海数学中心',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [272.16107447226307, 30.487998867473365, 229.85224331089097],
        cameraPosition: [229.10210706810363, 190.6609421208998, 491.1376176947856],
        cameraControls:[311.10497205852226, -9.738810610356133, 157.78580804022744],
        type: "Sprite",
    },
    {
        name: '',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [594.1011299819496, 43.24412541391602, 384.4030587759069],
        cameraPosition: [439.42607442490953, 280.4437072630144, 759.9867584185006],
        cameraControls: [679.3293353122097, -69.4219041360142, 293.2634241362711],
        type: "Sprite",
    },
    {
        name: '李兆基图书馆',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [154.64809599681908, 16.209424942749024, 161.06244478821455],
        cameraPosition: [-72.0043818258477, 129.41843961497543, -47.231028940275735],
        cameraControls: [118.87317519563811, -1.433792612593529, 179.19591371133416],
        type: "Sprite",
    },
    {
        name: '综合保障楼',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [-277.20322124862156, 12.10000024536133, 345.23613459962843],
        cameraPosition: [-237.0170010484645, 178.08038016735935, 53.414792864743006],
        cameraControls:[-265.43607513673885, 89.31056988244201, 170.9389928356054],
        type: "Sprite",
    },
    {
        name: '',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [594.1011299819496, 43.24412541391602, 384.4030587759069],
        cameraPosition: [439.42607442490953, 280.4437072630144, 759.9867584185006],
        cameraControls: [679.3293353122097, -69.4219041360142, 293.2634241362711],
        type: "Sprite",
    },
    {
        name: '教学中心专家楼',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [-489.1323494708265, 15.300005914985352, 290.4281704799618],
        cameraPosition: [-413.9712189143946, 183.49242668379958, 33.37081862092485],
        cameraControls:[-439.6227741679807, 103.35479049350363, 139.4183133348867],
        type: "Sprite",
    },
    {
        name: '法学院',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [80.29948464242992, 24.100011840258787, 21.35536951462688],
        cameraPosition: [31.56201295630231, 213.34354263260275, 278.92947305245445],
        cameraControls: [82.70118546950603, 19.67468456657666, 24.56189009022759],
        type: "Sprite",
    },
    {
        name: 'A教学楼',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [-26.86583144026783, 8.100004152709946, -0.7094583992081702],
        cameraPosition: [-19.12507951604208, 116.05694611923342, 321.3616305638268],
        cameraControls: [2.4294586087559438, 17.986979944720485, 13.557484339745956],
        type: "Sprite",
    },
    {
        name: '学校食堂',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [-170.7107093240131, 19.80501174396973, -25.383146077569663],
        cameraPosition:[-83.03989720308697, 103.8500199534922, -359.12065297322545],
        cameraControls:[-91.98438191426573, 57.76702117422843, -70.71547201284565],
        type: "Sprite",
    },
    {
        name: '体育馆',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [-385.1780939770816, 12.099999961568452, 44.44193324907007],
        cameraPosition: [-439.3058625504819, 161.4736765414155, 332.1753942322946],
        cameraControls: [-331.8155828426065, 26.854981514483644, 38.11074727126039],
        type: "Sprite",
    },{
        name: '化学楼',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [667.7248410497705, 38.36812050442263, 313.21502903810034],
        cameraPosition: [547.235199380893, 248.79871200492082, 645.6597454186694],
        cameraControls: [620.6071255388247, -48.181184705805855, 265.40827198041615],
        type: "Sprite",
    },{
        name: '智华楼',
        id: buildingIndex++,
        info: '江湾校区是复旦大学“一体两翼”校区总体布局“一体”中的重要组成部分。校区一期工程建设自2003年12月30日破土动工，历经近五年的建设，一个宁静隽永，生机盎然，既蕴含新古典浪漫主义的优雅，又散发着现代气息的崭新校区已跃然于众人眼前。置身其间，不仅能品味到百年复旦的深厚积淀和文化底蕴，也能感触到新世纪复旦的强烈脉动。',
        position: [-42.31594850668432, 8.10000415270996, -72.82713338535278],
        cameraPosition: [-273.64571395652695, 88.81594359037923, -68.09307101244865],
        cameraControls:[70.14171846395448, -5.113108088704056, -109.15406632147491],
        type: "Sprite",
    },
]
export default [akbutton, tube, textSp,meters,buildingInfo]
