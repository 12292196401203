
export default [
  {
    name: '国贸大厦',
    position: [-218.4089150128667, 177.53370260091157, -21.851148072842484],
    cameraPosition: [-523, 173, 123]
  },
  {
    name: '白云酒店',
    position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
    cameraPosition: [-354, 109, 247]
  },
  {
    name: '天上人间',
    position: [204.16695931242197, 15.57460021972656, 52.03525328999084],
    cameraPosition: [429, 109, 118]
  },
  {
    name: '沙湾小区',
    position: [290.68172190553724, 6.095502289736345, -60.573421562674056],
    cameraPosition: [451, 109, -94]
  },
  {
    name: '污水处理厂',
    position: [-33.913844660166816, 12.012800216674805, -383.4020480867691],
    cameraPosition: [-77, 152, -785]
  },
  {
    name: '别墅区1栋',
    position: [-98.58868371035445, 9.48631020407819, -9.058968960899808],
    cameraPosition: [-362, 99, 25]
  },
  {
    name: '别墅区2栋',
    position: [-185.93441755610698, 10.04939666223044, -117.15708846318181],
    cameraPosition: [-382, 109, -271]
  },
  {
    name: '别墅区3栋',
    position: [33.15387197944849, 10.535675775898024, -54.45645462698389],
    cameraPosition: [-18, 83, -302]
  },
  // {
  //   name: '奥特公司',
  //   position: [410.9358518960516, 26.736099243164062, -203.07294395435093],
  //   cameraPosition: [744, 158, -321]
  // },
  // {
  //   name: '电力局',
  //   position: [-361.5713030850417, 23.545799255371094, -92.57762499120719],
  //   cameraPosition: [-605, 132, -136]
  // }
]
