<template>
  <div class="right animated slideInLeft">
    <div class="title1">
      <font face="楷体">{{ page }}</font>
    </div>
    <div class="box">
      <div class="data">
        <div class="bg">
          <img :src="img_src" />
          <div class="title">{{ title }}</div>
        </div>
        <div>
          <table id="partnerTable">
            <thead>
              <tr>
                <th rowspan="2">口径</th>
                <th width="110" colspan="2">尺寸</th>
                <th>重量</th>
                <th  colspan="2">法兰</th>
              </tr>
              <tr>
                <th>总长</th>
                <th>高度</th>
                <th>整表</th>
                <th>外径</th>
                <th>单边螺栓数与孔直径</th>
              </tr>
            </thead>
            <tbody>
              <!-- 循环出有多少行数据，即 list 中有多少条数据，得到 list 中的每个元素 -->
              <tr v-for="(item, index) in tableData" :key="index">
                <!-- 循环取到元素的每个属性，并展示 -->
                <td v-for="(val, index) in item" :key="index">{{ val }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "excelCode",
  data() {
    return {
      img_src: require("./../assets/images/right.png"),
      title: "尺寸和重量",
      page: "水平螺翼可拆式冷水水表",
      tableData: [],
    };
  },
  created() {},
  mounted() {
    this.getText();
  },
  methods: {
    getText() {
      this.tableData = [
        {
          item1: "DN",
          item2: "L(mm)",
          item3: "H(mm)",
          item4: "kg",
          item5: "mm",
          item6: "",
        },
        {
          item1: "40",
          item2: "220",
          item3: "120",
          item4: "7.4",
          item5: "150",
          item6: "4-Ø19",
        },
        {
          item1: "50",
          item2: "220",
          item3: "120",
          item4: "7.7",
          item5: "165",
          item6: "4-Ø19",
        },
        {
          item1: "65",
          item2: "200",
          item3: "120",
          item4: "10.0",
          item5: "185",
          item6: "4-Ø19",
        },
        {
          item1: "80",
          item2: "225",
          item3: "150",
          item4: "14.0",
          item5: "200",
          item6: "4/8-Ø19",
        },
        {
          item1: "100",
          item2: "250",
          item3: "150",
          item4: "18.0",
          item5: "220",
          item6: "8-Ø19",
        },
        {
          item1: "125",
          item2: "250",
          item3: "160",
          item4: "20.5",
          item5: "250",
          item6: "8-Ø19",
        },
        {
          item1: "150",
          item2: "300",
          item3: "177",
          item4: "35.5",
          item5: "285",
          item6: "8-Ø23",
        },
        {
          item1: "200",
          item2: "350",
          item3: "206",
          item4: "50.5",
          item5: "340",
          item6: "8/12-Ø23",
        },
        {
          item1: "250",
          item2: "450",
          item3: "231",
          item4: "72.3",
          item5: "405",
          item6: "12-Ø28",
        },
        {
          item1: "300",
          item2: "500",
          item3: "256",
          item4: "99.3",
          item5: "460",
          item6: "12-Ø28",
        },
      ];
    },
  },
};
</script>
<style lang='less' scoped>
.right {
  position: fixed;
  color: #fff;
  right: 2%;
  width: 40%;
  height: 90%;
  .data {
    padding: 1rem 1.5rem;
    .bg {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5rem;

      img {
        height: 40px;
        width: 40px;
        vertical-align: middle;
      }
    }
    .title {
      color: #fff;
      font-size: 16px;
      height: 35px;
      line-height: 35px;
      padding: 0 0.5rem;
    }
  }
}
.box {
  background-image: url("./../assets/images/box.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 85%;
}
.title1 {
  width: 95%;
  font-size: 20px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  background-image: url("./../assets/images/title.png");
  background-size: 100% 100%;
  padding-left: 5%;
}
table {
  border: 1px solid #fff;
  margin: 0 auto;
  border-collapse: collapse;
}
table th {
  border: 1px solid #fff;
  padding: 0 0.5rem;
  width: 55;
}
td {
  height: 32px;
  border: 1px solid #fff;
  text-align: center;
  vertical-align: center;
}
</style>
