<template>
  <div class="main">
    <div class="header">
      <span>智慧城市大数据平台</span>
    </div>
    <div id="screen" class="screen"></div>
    <div class="downBottom">
      <div
          class="btn"
          v-for="(item, key) in address"
          :key="key"
          @click="fly(item.name)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import ZThree from "@/three/ZThree";
import curves from "@/three/curve";
import { curves3 } from "@/three/curve";
import { createSprite } from "@/three/sprite";
import address from "@/assets/mock/address.js";
import * as THREE from "three";
let app,
    camera,
    scene,
    renderer,
    renderOrder = 0,
    controls,
    clock,
    cityModel,
    // 车轮数组
    carWheel = [],
    cavasHtmlGroup = new THREE.Group(),
    buildingGroup = new THREE.Group(),
    tubeGroup = new THREE.Group(),
    textureLoader = new THREE.TextureLoader(),
    skyTexture,
    bloomComposer,
    speed = 0.0005,
    s = 0,
    p = 1,
    range = 1000,
    cameraCurve,
    linespeed = 0.001,
    isCameraMove = false,
    progress = 0;

export default {
  name: "Home",
  components: {
  },
  data() {
    return {
      // 小车移动状态
      isCarMove: false,
      address: address,
      isLoaded: false,
      loading: null,
    };
  },
  methods: {
    async initZThree() {
      app = new ZThree("screen");
      app.initThree();
      app.initHelper();
      app.initOrbitControls();
      app.initLight();
      skyTexture = app.loaderSky("texture/sky2/");
      bloomComposer = app.bloomComposer();
      // 点击精灵飞行
      app.initRaycaster((obj) => {
        if (obj.isSprite) {
          address.forEach((item) => {
            if (item.name === obj.name) {
              app.flyTo({
                position: item.cameraPosition,
                duration: 1500,
              });
            }
          });
        }
      });
      window.app = app;
      camera = app.camera;
      scene = app.scene;
      renderer = app.renderer;
      controls = app.controls;
      controls.maxPolarAngle = Math.PI / 2.2; // 设置最大角度 防止 入地下
      controls.target = new THREE.Vector3(2, 44, -32);
      clock = new THREE.Clock();

      cityModel = await app.loaderGltfDracoModelOld("model/city/", "modelDraco",
          (percentage) => {
            // if(this.loading&&this.loading.visible)
            // this.loading.text = "资源正在加载中，请稍后"+"···"+percentage+"%";
          });

      let lineMaterial = new THREE.LineBasicMaterial({
        color: "#57d8ff",
        transparent: true,
        linewidth: 5,
        opacity: 1.0,
      });

      cityModel.traverse((model) => {
        if (model.isMesh) {
          let edges = new THREE.EdgesGeometry(model.geometry, 1);
          let obj = new THREE.LineSegments(edges, lineMaterial);
          buildingGroup.add(obj);
        }
      });
      buildingGroup.renderOrder = renderOrder++;
      // scene.add(buildingGroup);

      // 添加平面
      let plane = app.loaderPlane("texture/plane.png");
      plane.position.set(0, -10, 0);
      plane.renderOrder = 2;
      scene.add(plane);

      // 红色线贴图
      let redLineTexture = textureLoader.load("texture/red_line.png");
      redLineTexture.wrapS = redLineTexture.wrapT = THREE.RepeatWrapping;
      redLineTexture.repeat.set(1, 1);
      redLineTexture.needsUpdate = true;

      // 绿色线贴图
      let greenLineTexture = textureLoader.load("texture/green_line.png");
      greenLineTexture.wrapS = greenLineTexture.wrapT = THREE.RepeatWrapping;
      greenLineTexture.repeat.set(1, 1);
      greenLineTexture.needsUpdate = true;

      let tubeRedMaterial = new THREE.MeshBasicMaterial({
        map: redLineTexture,
        side: THREE.BackSide,
        transparent: true,
      });

      let tubeGreenMaterial = new THREE.MeshBasicMaterial({
        map: greenLineTexture,
        side: THREE.BackSide,
        transparent: true,
      });

      // 创建道路
      curves.forEach((curve, index) => {
        let tube = app.loaderTube(
            curve,
            index % 2 === 0 ? tubeRedMaterial : tubeGreenMaterial
        );
        tubeGroup.add(tube);
      });
      cameraCurve = curves;
      // isCameraMove = true;
//弧线射线
      curves3.forEach((curve, index) => {
        let tube = app.loaderTube(
            curve,
            index % 2 === 0 ? tubeRedMaterial : tubeGreenMaterial
        );
        tubeGroup.add(tube);
      });
      tubeGroup.renderOrder = renderOrder++;
      scene.add(tubeGroup);

      // 创建文本

      address.forEach((item) => {
        createSprite(cavasHtmlGroup, item.name, item.position);
      });
      cavasHtmlGroup.renderOrder = renderOrder++;
      scene.add(cavasHtmlGroup);

      // 创建扩散波
      // let diffusion = app.loaderDiffusion("texture/test1.png");
      // diffusion.position.set(
      //     -255.8586862085735,
      //     20.000000000000012,
      //     -54.70926350083916
      // );
      // diffusion.renderOrder = 100;
      // scene.add(diffusion);

      // 加载粒子背景
      let points = app.laoderPartcle(1000, 200, 'texture/snow.png');
      points.position.set(0, 0, -127);
      scene.add(points);

      // 创建两条不规则但相平行的线
      const lineGeometry1 = new THREE.Geometry();
      lineGeometry1.vertices.push(new THREE.Vector3(-300, -150, 0));
      lineGeometry1.vertices.push(new THREE.Vector3(-100, 150, 0));
      const line1 = new THREE.Line(lineGeometry1, new THREE.LineBasicMaterial({ color: 0xffffff }));

      const lineGeometry2 = new THREE.Geometry();
      lineGeometry2.vertices.push(new THREE.Vector3(100, 150, 0));
      lineGeometry2.vertices.push(new THREE.Vector3(300, 450, 0));
      const line2 = new THREE.Line(lineGeometry2, new THREE.LineBasicMaterial({ color: 0xffffff }));
      // 将两条线添加到场景中
      // scene.add(line1);
      // scene.add(line2);

      // 创建面
      // const faceGeometry = new THREE.Geometry();
      // // 将两条线的顶点加入到面中
      // faceGeometry.vertices.push(lineGeometry1.vertices[0]);
      // faceGeometry.vertices.push(lineGeometry1.vertices[1]);
      // faceGeometry.vertices.push(lineGeometry2.vertices[1]);
      // faceGeometry.vertices.push(lineGeometry2.vertices[0]);
      // // 创建面的四个三角形
      // faceGeometry.faces.push(new THREE.Face3(0, 1, 2));
      // faceGeometry.faces.push(new THREE.Face3(2, 3, 0));
      // faceGeometry.faces.push(new THREE.Face3(2, 1, 0));
      // faceGeometry.faces.push(new THREE.Face3(0, 3, 2));
      //
      // const faceMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, opacity: 0.5, transparent: true });
      // const faceMesh = new THREE.Mesh(faceGeometry, faceMaterial);
      // // 将面添加到场景中
      // scene.add(faceMesh);


      this.loading.close();

      camera.position.set(-41, 1021, 312);

      app.flyTo({
        position: [17, 162, 487],
        duration: 3000,
      });

      app.render(() => {
        controls.update(clock.getDelta());
        // renderer.render(scene, camera);
        bloomComposer.render();
        TWEEN.update();
        if (isCameraMove) {
          if (progress <1 ) {
            let point = cameraCurve.getPointAt(progress); //获取样条曲线指定点坐标，作为相机的位置
            const x=point.x*controls.target.x/camera.position.x;
            const y=point.y*controls.target.y/camera.position.y;
            const z=point.z*controls.target.z/camera.position.z;

            const vectorAB = new THREE.Vector3(point.x-camera.position.x
                , point.y-camera.position.y, point.z-camera.position.z);
            const vectorBC = vectorAB.normalize().multiplyScalar(6)
            vectorBC.add(new THREE.Vector3(point.x, point.y, point.z))
            const tangent = cameraCurve.getTangentAt(progress);
            // let tangent = cameraCurve.getPointAt(progress + 0.00001) //获取样条曲线指定点坐标
            const lookAtVec = tangent.add(point);
            console.log(point)
            app.camera.position.set(point.x, point.y, point.z);
            app.controls.target.set(vectorBC.x, vectorBC.y, vectorBC.z);
            // app.controls.update();
            progress += linespeed;
          }else {
            isCameraMove = false;
          }
        }

        if (points) {
          let vertices = points.geometry.vertices;
          vertices.forEach((v) => {
            // 计算位置
            v.y = v.y - v.velocityY;
            v.x = v.x - v.velocityX;
            v.z = v.z - v.velocityZ;

            // 边界检查
            if (v.y <= -range / 2) v.y = range / 2;
            if (v.x <= -range / 2 || v.x >= range / 2) v.x = v.x * -1;
            if (v.z <= -range / 2 || v.z >= range / 2)
              v.velocityZ = v.velocityZ * -1;
          });
          points.geometry.verticesNeedUpdate = true;
        }

        if (redLineTexture) {
          redLineTexture.offset.x += 0.005;
        }

        if (greenLineTexture) {
          greenLineTexture.offset.x += 0.005;
        }

        s += 0.01;
        p -= 0.005;
        if (s > 2) {
          s = 0;
          p = 1;
        }
        // diffusion.scale.set(1 + s, 1, 1 + s);
        // diffusion.material[0].opacity = p;
      });
    },
    fly(name) {
      address.forEach((item) => {
        if (item.name === name) {
          app.flyTo({
            position: item.cameraPosition,
            duration: 1500,
          });
        }
      });
    },
    // cameraMoveFly(){
    //   if(moveIndex>10)
    //     moveIndex = 0;
    //   let y = 5;
    //   let route1 = [
    //     new THREE.Vector3(
    //         558, y, 500
    //     ),
    //     new THREE.Vector3(
    //         558, y, 489
    //     ),
    //     new THREE.Vector3( 540.0, y, 422.4),
    //     new THREE.Vector3( 527.1, y, 383.0),
    //     new THREE.Vector3( 510.2, y, 345.9),
    //     new THREE.Vector3( 515.4, y, 314.9),
    //     new THREE.Vector3( 487.8, y, 306.6),
    //     new THREE.Vector3( 456.7, y, 261.4),
    //     new THREE.Vector3( 415.1, y, 213.2),
    //     new THREE.Vector3( 411.0, y, 185.6),
    //     new THREE.Vector3( 383.6, y, 186.2),
    //   ];
    //
    //   let item = route1[moveIndex];
    //   let nitem = route1[moveIndex+1];
    //   moveIndex++;
    //   app.flyTo({
    //     position: [item.x, item.y, item.z],
    //     controls: [nitem.x, nitem.y, nitem.z],
    //     duration:1500,
    //   },this.cameraMoveFly)
    //
    // },
  },
  mounted() {
    this.loading = this.$loading({
      lock: true,
      text: "拼命加载中",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 1)",
    });
    this.initZThree();
  },
};
</script>

