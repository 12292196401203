import * as THREE from "three";
let y = 5;
let y1 = 48;

function getVector3(v3) {
    let d = [];
    let sum = 50;
    const addData = (item) => {
        if (d.length == 0)
            d.push(item);
        else {
            let lastItem = d[d.length - 1];
            let deptx = (item.x - lastItem.x) / sum;
            let depty = (item.y - lastItem.y) / sum;
            let deptz = (item.z - lastItem.z) / sum;
            for (let i = 1; i < sum; i++) {
                d.push(new THREE.Vector3(
                    lastItem.x + deptx * i, lastItem.y + depty * i, lastItem.z + deptz * i
                ));
            }
            d.push(item);
        }
    };
    v3.forEach((item) => {
        addData(item);
    })
    let route = new THREE.CatmullRomCurve3(d, false);
    return route;
}


let route1 = [
    new THREE.Vector3(
        96, y, 420
    ),
    new THREE.Vector3(
        96, y, 323
    ),
    new THREE.Vector3(
        98, y, 323
    ),

    new THREE.Vector3(
        310, y, 323
    ),
];
let route2 = [
    new THREE.Vector3(
        310, y, 323
    ),
    new THREE.Vector3(
        332, y, 323
    ),

    new THREE.Vector3(
        334, y, 323
    ),
    new THREE.Vector3(
        334, y, 182
    ),
    new THREE.Vector3(
        334, y1, 182
    ),
    new THREE.Vector3(
        36, y1, 20
    ),
    new THREE.Vector3(
        36, y, 30
    ),
    new THREE.Vector3(
        36, y, 100
    ),
];
let route3 = [
    new THREE.Vector3(
        36, y, 100
    ),
    new THREE.Vector3(
        36, y, 318
    ),

    new THREE.Vector3(
        36, y, 320
    ),
    new THREE.Vector3(
        108, y, 320
    ),
    new THREE.Vector3(
        108, y, 220
    ),
];
let gdy = 1;
let route4 = [
    new THREE.Vector3(
        136, gdy, 407
    ),
    new THREE.Vector3(
        136, gdy, 399
    ),
    new THREE.Vector3(
        160, gdy, 399
    ),
    new THREE.Vector3(
        160, gdy, 400
    ),
    new THREE.Vector3(
        190, gdy, 400
    ),
    new THREE.Vector3(
        190, gdy, 399
    ),
    new THREE.Vector3(
        216, gdy, 399
    ),
    new THREE.Vector3(
        216, gdy, 360
    )
];
let route4_5 = [
    new THREE.Vector3(
        138, gdy, 407
    ),
    new THREE.Vector3(
        138, gdy, 401
    ),
    new THREE.Vector3(
        160, gdy, 401
    ),
    new THREE.Vector3(
        160, gdy, 400
    ),
    new THREE.Vector3(
        190, gdy, 400
    ),
    new THREE.Vector3(
        190, gdy, 401
    ),
    new THREE.Vector3(
        218, gdy, 401
    ),
    new THREE.Vector3(
        218, gdy, 360
    ),
];
let route5 = [
    new THREE.Vector3(
        346, gdy, 323
    ),
    new THREE.Vector3(
        341, gdy, 323
    ),

    new THREE.Vector3(
        341, gdy, 377
    ),
    new THREE.Vector3(
        334, gdy, 388
    ),
    new THREE.Vector3(
        317, gdy, 399
    ),

    new THREE.Vector3(
       226, gdy, 399
    ),
    new THREE.Vector3(
       226, gdy, 360
    ),
];
let route6 = [
    new THREE.Vector3(
        346, gdy, 325
    ),
    new THREE.Vector3(
        343, gdy, 325
    ),

    new THREE.Vector3(
        343, gdy, 378
    ),
    new THREE.Vector3(
        336, gdy, 390
    ),
    new THREE.Vector3(
        318, gdy, 401
    ),

    new THREE.Vector3(
        224, gdy, 401
    ),
    new THREE.Vector3(
        224, gdy, 360
    )
];
let route7 = [
    new THREE.Vector3(
        209, gdy, 334
    ),
    new THREE.Vector3(
        209, gdy, 321
    ),
    new THREE.Vector3(
        -15, gdy, 321
    ),
    new THREE.Vector3(
        -15, gdy, -77
    ),
];
let route8 = [
    new THREE.Vector3(
        68, gdy, 212
    ),
    new THREE.Vector3(
        37, gdy, 212
    ),
    new THREE.Vector3(
        37, gdy, -45
    ),
    new THREE.Vector3(
        308, gdy, -45
    ),
];
let route9 = [
    new THREE.Vector3(
        220, gdy, 338
    ),
    new THREE.Vector3(
        260, gdy, 338
    ),
];
let route10 = [
    new THREE.Vector3(
        245, gdy, 338
    ),
    new THREE.Vector3(
        245, gdy, 388
    ),
    new THREE.Vector3(
        260, gdy, 388
    ),
];
let route11 = [
    new THREE.Vector3(
        245, gdy, 364
    ),
    new THREE.Vector3(
        260, gdy, 364
    ),
];
let route12 = [
    new THREE.Vector3(
        216, gdy, 335
    ),
    new THREE.Vector3(
        216, gdy, 320
    ),
    new THREE.Vector3(
        335, gdy, 320
    ),
    new THREE.Vector3(
        335, gdy, -50
    ),
];
export let akbutton = [
    {
        name: '视角复位',
        position: [-33.913844660166816, 12.012800216674805, -383.4020480867691],
        controls: [108.21390562685808, -129.51278441906786, 67.9566070128961],
        cameraPosition: [433.456706289105, 219.68312417497387, 540.5150079031882],
        type: "camera",

    },
    {
        name: '步行模式',
        position: [-218.4089150128667, 177.53370260091157, -21.851148072842484],
        cameraPosition: [-523, 173, 123],
        route: getVector3(route1),
        type: "line",
        speed: 0.0005
    },
    {
        name: '数据图表',
        position: [-218.4089150128667, 177.53370260091157, -21.851148072842484],
        cameraPosition: [-523, 173, 123],
        route: getVector3(route1),
        type: "data",
        speed: 0.0005
    },
    // {
    //     name: '绿化喷滴灌',
    //     position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
    //     cameraPosition: [-354, 109, 247],
    //     route: getVector3(route2),
    //     type: "line",
    //     speed: 0.001
    // },
    // {
    //     name: '一、二期',
    //     position: [290.68172190553724, 6.095502289736345, -60.573421562674056],
    //     cameraPosition: [451, 109, -94],
    //     route: getVector3(route3),
    //     type: "line",
    //     speed: 0.001
    // },
]

export let tube = [
    {
        name: '管道总表到水泵房1',
        position: [-218.4089150128667, 177.53370260091157, -21.851148072842484],
        cameraPosition: [-523, 173, 123],
        route: getVector3(route4),
        type: "tube",
        model: "DN150",
        matera: "铸铁",
        speed: 0.002
    },
    {
        name: '管道总表到水泵房1_2',
        position: [-218.4089150128667, 177.53370260091157, -21.851148072842484],
        cameraPosition: [-523, 173, 123],
        route: getVector3(route4_5),
        type: "tube",
        model: "DN150",
        matera: "铸铁",
        speed: 0.002
    },
    {
        name: '市政',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route5),
        type: "tube",
        model: "DN150",
        matera: "铸铁",
        speed: 0.002
    },
    {
        name: '市政',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route6),
        type: "tube",
        model: "DN100",
        matera: "PVC",
        speed: 0.002
    },
    {
        name: '管道水泵房到绿化111',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route7),
        type: "tube",
        model: "DN100",
        matera: "PVC",
        speed: 0.002
    },
    {
        name: '管道水泵房到绿化222内圈',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route8),
        type: "tube",
        model: "DN100",
        matera: "PVC",
        speed: 0.002
    },
    {
        name: '宿舍9',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route9),
        type: "tube",
        model: "DN100",
        matera: "PVC",
        speed: 0.002
    },
    {
        name: '宿舍10',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route10),
        type: "tube",
        model: "DN100",
        matera: "PVC",
        speed: 0.002
    },
    {
        name: '宿舍11',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route11),
        type: "tube",
        model: "DN100",
        matera: "PVC",
        speed: 0.002
    },
    {
        name: '水箱出',
        position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
        cameraPosition: [-354, 109, 247],
        route: getVector3(route12),
        type: "tube",
        model: "DN100",
        matera: "PVC",
        speed: 0.002
    },
]
export let meters = [
    {
        name: '德堡路市政总表',
        position: [96, 1.2, 418],
        rotateXYZ: [0, Math.PI / 2, 0],
        cameraPosition: [-523, 173, 123],
        type: "meter",
        meterId: 13
    }, {
        name: '富特南路市政总表',
        position: [338, 1.2, 323],
        rotateXYZ: [0, 0, 0],
        cameraPosition: [-523, 173, 123],
        type: "meter",
        meterId: 12
    }
]
export let textSp = [
    // {
    //     name: 'A栋',
    //     position: [111.71235587654394, 13.911935671301023, 114.76128190961768],
    //     type: "Sprite",
    // },
    // {
    //     name: 'P栋',
    //     position: [248.29313710850641, 45.91567892535305, 124.72028873710038],
    //     type: "Sprite",
    // },
    {
        name: 'CUB1',
        position: [110.8444301269246, 13.842427988211568, 116.51493951457337],
        type: "Sprite",
    },
    {
        name: 'CUB2',
        position: [111.08357550046264, 23.431150950567257, 221.3664760672602],
        type: "Sprite",
    },
    {
        name: 'CUB3',
        position: [158.56460493318892, 15.054485321044922, 274.23261851802715],
        type: "Sprite",
    },
    {
        name: 'M3',
        position: [186.09763924314748, 23.72625350952205, 376.1676261177952],
        type: "Sprite",
    },
    {
        name: 'M2',
        position: [248.881698473823, 45.92611464894469, 254.09682424865755],
        type: "Sprite",
    },
    {
        name: 'M1',
        position: [243.98768536342902, 45.53294461183282, 75.61491231216199],
        type: "Sprite",
    },
]
let py = -0.5;
export let pengquan = [
    [54, py, 215]
    , [54, py, 200]
    , [54, py, 145]
    , [54, py, 130]
    , [36, py, 40]
]

export default [akbutton, tube, textSp, pengquan, meters]
// 12	市政科技园区域表	科技园	S1-3	S0-1
// 13	市政赤峰路（2）	赤峰路（2）	S1-1	S0-1
// 15	科技园1号	科技园1号	S1-2	S0-1
// 16	科技园2号	科技园2号	S2-1	S1-3
// 17	西南十楼	西南十楼	S2-2	S1-3
// 18	购物中心	购物中心	S2-3	S1-3
// 19	会务中心3号楼	会务中心3号楼	S2-4	S1-3
// 20	会务中心4号楼	会务中心4号楼	S2-5	S1-3
// 21	体育馆(1)	体育馆(1)	S2-6	S1-1
// 22	体育馆(2)	体育馆(2)	S2-7	S1-1
// 23	档案馆	档案馆	S2-8	S1-1
// 24	区域表1	区域表1	S2-9	S1-1
// 25	区域表2	区域表2	S2-10	S1-1
// 26	第三教学楼	第三教学楼	S3-40	S2-9
// 27	人文学院	人文学院	S3-41	S2-9
// 28	西南十一楼（1）	西南十一楼（1）	S3-42	S2-10
// 29	西南十一楼（2）	西南十一楼（2）	S3-43	S2-10
// 30	微电子中心	微电子中心	S3-44	S2-10
// 31	五食堂（1）	五食堂（1）	S3-45	S2-10
// 32	五食堂（2）	五食堂（2）	S3-46	S2-10
// 33	五食堂（3）	五食堂（3）	S3-47	S2-10
// 34	五食堂（4）	五食堂（4）	S3-48	S2-10
// 35	后勤公寓2号楼	后勤公寓2号楼	S3-49	S2-10
// 36	浴室	浴室	S3-50	S2-10
// 37	后勤公寓	后勤公寓	S3-51	S2-10
// 38	高等技术学院	高等技术学院	S3-52	S2-10
// 39	第一教学楼	第一教学楼	S3-53	S2-10
// 40	继续教育学院	继续教育学院	S3-54	S2-10
// 41	第一实验楼	第一实验楼	S3-55	S2-10
// 42	西南十二楼	西南十二楼	S3-56	S2-10
// 200	蓝牙摄像实验	蓝牙摄像实验	S2-31	S1-2
