import * as THREE from 'three';
// import testVertexShader from '../shader/vertex.glsl';
// import testFragmentShader from '../shader/fragment.glsl';
/**
 * Threejs自来水厂3D可视化，加载模型，水流监测，标签展示
 */
function shuichang(mesh) {
    this.mesh = mesh;
    this.count = 200
    this.gColor = '#28f260'
    this.prop1 = {
        width: 416,
        height: 112,
        pos: [108, 4, 180],
        // scale:[24, 6, 0]
        scale: [0.024375 * this.count, 0.0065625 * this.count, 0]
    }
    this.tab1 = [
        ['一级高压泵后压力：', '20.2bar', this.gColor],
        ['一级循环泵后压力：', '0.2bar', ]
    ]

    this.prop2 = {
        width: 352,
        height: 196,
        pos: [98, 7, 204],
        scale: [0.020625 * this.count, 0.011484375 * this.count, 0],
    }

    this.tab2 = [
        ['进水情况', ''],
        ['进水温度：', '25.6℃', this.gColor],
        ['进水流量：', '2.5m³', ],
        ['进水电导：', '28.5ms/cm', this.gColor],
    ]

    this.prop3 = {
        width: 384,
        height: 256,
        pos: [110, 5, 180],
        scale: [0.0225 * this.count, 0.015 * this.count, 0]
    }
    this.tab3 = [
        ['产水情况', ''],
        ['一级回收率：', '58%', this.gColor],
        ['一级产水流量：', '1.75㎡', ],
        ['一级产水电量：', '980.5/cm', this.gColor],
        ['一级产水电量：', '0.5bar', this.gColor],
    ]

    this.prop4 = {
        width: 256,
        height: 64,
        pos: [104, 4, 204],
        scale: [0.015 * this.count, 0.00375 * this.count, 0],
    }
    this.tab4 = [
        ['泵机状态 ', '• 开启', this.gColor],
    ]

    this.prop5 = {
        width: 256,
        height: 64,
        pos: [-10, 50, 30],
        scale: [0.015 * this.count, 0.00375 * this.count, 0],
    }
    this.tab5 = [
        ['阀门状态 ', '• 开启', this.gColor],
    ]
    this.props = [this.prop1, this.prop2, this.prop3, this.prop4]
    this.tabs = [this.tab1, this.tab2, this.tab3, this.tab4]
    this.handleDatachange();
    setInterval(() =>{this.handleDatachange();}, 10000)//周期刷新数据
}

Object.assign(shuichang.prototype, {
    constructor: shuichang,
    handleDatachange: function() {
        let r = (Math.random() * 10 + 20).toFixed(2)
        this.tab2[1][1] = r + '℃'
        if (r > 25) this.tab2[1][2] = 'red'
        else this.tab2[1][2] = this.gColor

        r = Math.random().toFixed(2)
            this.tab3[4][1] = r + 'bar'
        if (r > 0.5) this.tab3[4][2] = 'red';
        else this.tab3[4][2] = this.gColor

        if (Math.random() > 0.5) {
            this.tab5[0][1] = '• 开启'
            this.tab5[0][2] = this.gColor
        } else {
            this.tab5[0][1] = '• 关闭'
            this.tab5[0][2] = 'red'
        }
        this.initSprite()
    },
    initSprite: function() {
        this.clearSprite();
        const borderColor = 'rgba(39, 179, 236, 1)'
        const color = 'rgba(0,0,0, 1)'
        this.makeTextSprite1(this.tabs, this.props, {
            color: color,
            borderColor,
            backgroundColor: 'rgba(39, 179, 236,0.3)'
        });
    },
    // 清空雪碧图
    clearSprite: function(type = 'Sprite') {
        const children = [];
        (this.mesh.children || []).forEach((v) => {
            if (v.jc_type !== type) {
                children.push(v);
            }
        });
        this.mesh.children = children;
    },

        /* 创建字体精灵 */
    makeTextSprite1: function(tabs, props, parameters) {
        if (parameters === undefined) parameters = {}
        const _that = this;
        tabs.forEach((tab, k) => {
            let {
                width,
                height
            } = props[k]
            /* 创建画布 */
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d')
            canvas.width = width
            canvas.height = height
            let gap = 10
            let fontface = parameters.hasOwnProperty("fontface") ? parameters["fontface"] : "sans-serif"
            /* 字体大小 */
            let fontsize = parameters.hasOwnProperty("fontsize") ?
                parameters["fontsize"] : 30

            let color = parameters.hasOwnProperty("color") ? parameters["color"] : 'rgba(0, 0, 0, 1.0)'
            /* 边框厚度 */
            let borderWith = parameters.hasOwnProperty("borderWith") ? parameters["borderWith"] : 2
            /* 边框颜色 */
            // eslint-disable-next-line no-prototype-builtins
            let borderColor = parameters.hasOwnProperty("borderColor") ? parameters["borderColor"] : {
                r: 0,
                g: 0,
                b: 0,
                a: 1.0
            }
            /* 背景颜色 */
            let backgroundColor = parameters.hasOwnProperty("backgroundColor") ? parameters["backgroundColor"] : {
                r: 255,
                g: 255,
                b: 255,
                a: 1.0
            }
            /* 字体加粗 */
            // context.font = "Bold " + fontsize + "px " + fontface
            context.font = fontsize + "px " + fontface
            let unit = gap + fontsize
            /* 背景颜色 */
            context.fillStyle = backgroundColor
            /* 边框的颜色 */
            context.strokeStyle = borderColor
            context.lineWidth = borderWith
            /* 绘制圆角矩形 */
            this.roundRect(context, gap, gap, width - gap, height - gap, 4)

            tab.forEach((d, i) => {
                context.fillStyle = color;
                context.fillText(d[0], gap * 2, gap + unit * (i + 1))
                if (d[2]) {
                    context.fillStyle = d[2]
                }
                context.fillText(d[1], gap * 2 + this.measureText(d[0], context), gap + unit * (i + 1))
            })

            /* 画布内容用于纹理贴图 */
            let texture = new THREE.Texture(canvas);
            texture.needsUpdate = true
            let spriteMaterial = new THREE.SpriteMaterial({
                map: texture,
                // sizeAttenuation:false,
                // transparent:true
            });
            let sprite = new THREE.Sprite(spriteMaterial)
            // console.log(sprite.spriteMaterial)
            /* 缩放比例 */
            sprite.scale.set(...props[k].scale)
            sprite.center = new THREE.Vector2(0, 0);
            sprite.jc_type = 'Sprite'
            _that.mesh.add(sprite);
            sprite.position.set(...props[k].pos);
        })
    },

    measureText: function(text, ctx, font) {
        if (font) ctx.font = font
        return ctx.measureText(text).width;
    },
    /* 绘制圆角矩形 */
    roundRect: function(ctx, x, y, w, h, r) {

        ctx.beginPath();
        ctx.moveTo(x + r, y);
        ctx.lineTo(x + w - r, y);
        ctx.quadraticCurveTo(x + w, y, x + w, y + r);
        ctx.lineTo(x + w, y + h - r);
        ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
        ctx.lineTo(x + r, y + h);
        ctx.quadraticCurveTo(x, y + h, x, y + h - r);
        ctx.lineTo(x, y + r);
        ctx.quadraticCurveTo(x, y, x + r, y);
        ctx.closePath();

        // ctx.shadowColor = "#qb95cf";
        // ctx.shadowOffsetX = 0;
        // ctx.shadowOffsetY = 0;
        // ctx.shadowBlur = 4;

        ctx.fill();
        ctx.stroke();
        ctx.shadowColor = "";
        ctx.shadowOffsetX = 0;
        ctx.shadowOffsetY = 0;
        ctx.shadowBlur = 0;
    },


    makeTextSprite: function(container, scene) {
        console.log(container.scrollWidth, container.scrollHeight)
        return window.html2canvas(container, {
            //allowTaint:true,
            scale: 1,
            width: container.scrollWidth,
            height: container.scrollHeight,
            // useCORS: true
        })
            .then(function(canvas) {
                if (!canvas) return;
                // console.log(canvas)
                /* 画布内容用于纹理贴图 */
                let texture = new THREE.Texture(canvas);
                texture.needsUpdate = true
                let spriteMaterial = new THREE.SpriteMaterial({
                    map: texture,
                    // transparent:true
                });
                let sprite = new THREE.Sprite(spriteMaterial)
                // console.log(sprite.spriteMaterial)
                /* 缩放比例 */
                sprite.scale.set(30, 20, 0)
                sprite.position.set(0, 0, 10)
                sprite.center = new THREE.Vector2(0, 0);
                scene.add(sprite);
                return sprite
            });
    }

});

export default shuichang
