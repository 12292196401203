<template>
  <div class="main">
    <div id="screen" class="screen"></div>
    <transition mode="out-in" name="leftSlide">
      <div class="windowLeft"  v-show="leftShow">
        <big-left ref="Left"></big-left>
      </div>
    </transition>
    <transition mode="out-in" name="rightSlide">
      <div class="windowRight"  v-show="rightShow">
        <bigRight ref="Right"></bigRight>
      </div>
    </transition>
    <div class="downBottom">
      <div
          class="btn"
          v-for="(item, key) in akbutton"
          :key="key"
          @click="buttonClick(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div>
      <canvas ref="canvas"
              @keydown="onKeyDown"
              @keyup="onKeyUp"
              tabindex="0"></canvas>
    </div>
  </div>
</template>

<script>
import ZThree from "@/three/ZThree";
import { akbutton, tube,meters,buildingInfo } from "@/assets/mock/meterUtil.js";
import bigLeft from "@/components/code";
import bigRight from "@/components/textcode";
import * as THREE from "three";
import GLTFUtil from "@/utils/GLTFUtil";
import waterPipe from "@/assets/mock/waterPipe";
import BaseNet from "@/three/baseNet";
import meterData from "@/assets/mock/meterData";
let app,
    camera,
    scene,
    renderer,
    renderOrder = 0,
    serverInfo = {},
    modelUrlPath,
    lineMaterial,
    controls,
    direction = new THREE.Vector3(),
    clock,
    cityModel,
    imgMesh,
    speed = 0.2,
    waterpipe,
    meterdata,
    cavasHtmlGroup = new THREE.Group(),
    meterGroup = new THREE.Group(),
    buildingGroup = new THREE.Group(),
    tubeGroup = new THREE.Group(),
    tubeOtherGroup = new THREE.Group(),
    buildingShaderGroup = new THREE.Group()

export default {
  name: "meterUtil",
  components: {
    bigLeft,
    bigRight,
  },
  data() {
    return {
      // 小车移动状态
      isCarMove: false,
      currentItem: 1,
      isLoaded: false,
      akbutton: akbutton,
      loading: null,
      leftShow: false,
      rightShow: false,
      movement: {
        forward: false,
        backward: false,
        left: false,
        right: false
      }
    };
  },
  methods: {
    async initZThree() {
      app = new ZThree("screen");
      app.initThree();//
      app.initHelper();
      app.initOrbitControls();
      app.initLight(0.8);
      app.loaderSky("texture/sky2/");//

      window.app = app;
      camera = app.camera;
      scene = app.scene;
      renderer = app.renderer;
      controls = app.controls;
      controls.maxPolarAngle = Math.PI / 2.2; // 设置最大角度 防止 入地下
      controls.target = new THREE.Vector3(2, 44, -32);
      clock = new THREE.Clock();
      lineMaterial = new THREE.LineBasicMaterial({
        color: "#57d8ff",
        transparent: true,
        linewidth: 5,
        opacity: 1.0,
      });
      this.loadingModel();
// 点击精灵飞行
      app.initRaycaster((obj,point) => {
        console.log([point.x,point.y,point.z])
        console.log([camera.position.x,camera.position.y,camera.position.z])
        console.log([controls.target.x,controls.target.y,controls.target.z])
        var meteritem ;
        this.cleanPage();
        if(!obj)return;
        if(obj.useType&&obj.useType == "building"){
          buildingInfo.forEach((item) => {
            if (item.id == obj.name&&item.name!="") {
              this.rightShow = true;
              this.$refs["Right"].showPD(item.name,[item.info],item.name);
            }
          });
        }
        if(obj.type == "Mesh"&&obj.useType&&obj.useType == "waterTube"){
          tube.forEach((item) => {
            if (item.modelname == obj.name) {
              waterpipe.showData(item,[point.x,point.y,point.z]);
            }
          });
        }
        if (obj.isSprite) {
          buildingInfo.forEach((item) => {
            if (item.name === obj.name) {
              this.fuwei(item.cameraPosition,item.cameraControls);
            }
          });
        }
        if(obj.type === "Mesh"){
          meteritem = obj.parent;
        }else
          meteritem = obj;
        if(meteritem.jc_type&&meteritem.jc_type=='meter'){
          meterdata.changeMeter(meteritem,meteritem.meterId);
        }
      });
      waterpipe = new waterPipe(scene);
      camera.position.set(-41, 1021, 312);
      this.fuwei();
      cavasHtmlGroup.renderOrder = renderOrder++;
      scene.add(cavasHtmlGroup);

      meterGroup.renderOrder = renderOrder++;
      scene.add(meterGroup);
      // 创建水表
      meters.forEach((item) => {
        app.loadMeter(meterGroup,item.type,item.meterId,item.position,item.rotateXYZ,3.5);
      });


      app.render(() => {
        controls.update(clock.getDelta());
        tubeGroup.traverse((model) => {
          if (model.type === "Mesh"&&model.useType == "waterTube") {
            model.material.map.offset.x -= 0.01;//
          }
        })

        if(this.movement.backward||this.movement.forward||this.movement.left||this.movement.right){
          var cameraPosition = camera.position;
          var targetPosition = controls.target;
          direction.subVectors(targetPosition, cameraPosition);
          direction.normalize();
        }
        // 根据按键事件修改相机位置和方向
        if (this.movement.forward) {
          camera.position.x += direction.x * speed;
          camera.position.z += direction.z * speed;
          controls.target.x += direction.x * speed;
          controls.target.z += direction.z * speed;
          controls.update();
        }
        if (this.movement.backward) {
          camera.position.x -= direction.x * speed;
          camera.position.z -= direction.z * speed;
          controls.target.x -= direction.x * speed;
          controls.target.z -= direction.z * speed;
          controls.update();
        }
        if (this.movement.left) {
          controls.target.x += direction.z * speed;
          controls.target.z -= direction.x * speed;
          console.log(controls.target)
          controls.update();
        }
        if (this.movement.right) {
          controls.target.x -= direction.z * speed;
          controls.target.z += direction.x * speed;
          controls.update();
        }
        renderer.render(scene, camera);
        TWEEN.update();

      });
    },
    buttonClick(item) {
      this.cleanPage();
      this.currentItem = item.index;
      switch (item.index) {
        case 1://选中水表
          app.loadMeter(meterGroup,'meter',item.meterId,item.position,item.rotateXYZ,3.5);
          break;
        case 2://X+++

          break;
        case 3://X---

          break;
        case 4://Y+++

          break;
        case 5://Y---

          break;
        case 6://ROTATE

          break;
        default:

          break;
      }
    },
    exportModel() {
      GLTFUtil.exporterGlb(cityModel);
    },
    fuwei(pos,cons){
      app.flyTo({
        position: pos||[921, 266, 118],
        controls: cons||[326,  -80,  239],
        duration: 1500,
      });
    },
    cleanPage(){
      this.rightShow = false;
      this.leftShow = false;
      waterpipe.deleteData();
      meterdata.deleteData();
      imgMesh&&scene.remove(imgMesh);
    },
    confirm(mag) {
      this.$message({
        message: mag,
        type: "success",
        duration: 3000,
      });
    },
    loadingShow() {
      this.loading = this.$loading({
        lock: true,
        text: "资源正在加载中，请稍后",
        spinner: "el-icon-loading",
        background: "rgba(0,0,0,0.1)",
      });
    },
    async loadingModel() {
      let apath = modelUrlPath ||"model/city/haitong/";
      let addHeigth = 1;
      let redHeight = -0.5;
      tubeGroup.renderOrder = renderOrder++;
      tubeGroup.position.setY(addHeigth)
      scene.add(tubeGroup);

      //上水管道
      let itemModel = await app.loaderGlbDracoModel(
          apath,
          "3"
      );
      itemModel.traverse((model) => {
        if (model.isMesh) {
          model.frustumCulled = false;
          if (model.material ) {
            model.material  = app.loaderColorTube(false,5);
          }
          model.useType = "waterTube"
        }
      });
      console.log(itemModel)
      itemModel.renderOrder= renderOrder++;
      // itemModel.name = "i";
      itemModel.useType = "waterTube";
      tubeGroup.add(itemModel);

      //上水管道
      let reditemModel = await app.loaderGlbDracoModel(
          apath,
          "4"
      );
      reditemModel.traverse((model) => {
        if (model.isMesh) {
          model.frustumCulled = false;
          if (model.material ) {
            model.material  = app.loaderColorTube(true,5);
          }
          model.useType = "waterTube"
        }
      });
      console.log(itemModel)
      reditemModel.renderOrder= renderOrder++;
      reditemModel.name = "i";
      reditemModel.useType = "waterTube";
      reditemModel.position.setY(redHeight)
      tubeGroup.add(reditemModel);

      tubeOtherGroup.renderOrder = renderOrder++;
      tubeOtherGroup.position.setY(addHeigth)
      scene.add(tubeOtherGroup);

      this.loading.close();
    },
    // 处理按键按下事件
    onKeyDown(event) {
      switch (event.keyCode) {
        case 87: // w
          this.movement.forward = true;
          break;
        case 83: // s
          this.movement.backward = true;
          break;
        case 65: // a
          this.movement.left = true;
          break;
        case 68: // d
          this.movement.right = true;
          break;
      }
    },
    // 处理按键松开事件
    onKeyUp(event) {
      switch (event.keyCode) {
        case 87: // w
          this.movement.forward = false;
          break;
        case 83: // s
          this.movement.backward = false;
          break;
        case 65: // a
          this.movement.left = false;
          break;
        case 68: // d
          this.movement.right = false;
          break;
      }
    }
  },
  mounted() {
    this.loadingShow();
    this.baseNet = new BaseNet();
    this.baseNet.IsAgent(confirm);
    this.baseNet.getGPU(confirm);

    let ip = this.baseNet.getQueryString("IP");

    ip = ip.replaceAll("%3A", ":").replaceAll("%2F", "/");
    let USER_ID = this.baseNet.getQueryString("USER_ID");
    let TOKEN = this.baseNet.getQueryString("TOKEN");
    var success = this.baseNet.checkToken(ip,USER_ID,TOKEN);
    if(!success){
      // return;
    }
    serverInfo.ip = ip;
    serverInfo.USER_ID = USER_ID;
    serverInfo.TOKEN = TOKEN;
    meterdata = new meterData(serverInfo);
    // 监听按键事件
    window.addEventListener('keydown', this.onKeyDown, false);
    window.addEventListener('keyup', this.onKeyUp, false);
    this.initZThree();
  },
};
</script>
