
export default [
  {
    name: '复位',
    controls: [0,0,0],
    cameraPosition: [41,56,135],
  },
  {
    name: '切换',
    position: [-218.4089150128667, 177.53370260091157, -21.851148072842484],
    cameraPosition: [-523, 173, 123]
  },
  {
    name: '特征',
    position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
    cameraPosition: [-354, 109, 247]
  },
  {
    name: '透视',
    position: [-112.91255179943249, 34.0192985534668, 71.60845959057795],
    cameraPosition: [-354, 109, 247]
  },
]
